<template>
  <div class="container">
    <!-- 卡片 -->
    <el-card class="box-card">
      <!-- 添加按钮 -->
      <el-button type="primary" size="small" @click="teacherShow = true">添加线下课程</el-button>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id" width="150"></el-table-column>
        <el-table-column align="center" label="课程名称" prop="courseName"></el-table-column>
        <el-table-column align="center" label="讲师名称" prop="lecturerName"></el-table-column>
        <el-table-column align="center" label="课程单价" prop="price"></el-table-column>
        <el-table-column align="center" label="总报名数量" prop="totalApplyCount"></el-table-column>
        <el-table-column align="center" label="是否已上传海报" prop="sharePosterImg">
          <template slot-scope="scope">
            <div class="poster-box">
              <el-tag v-if="!scope.row.sharePosterImg" type="danger" disable-transitions>否</el-tag>
              <span class="look-poster" v-else @click="lookPosterImg(scope.row.sharePosterImg)" v-popover:popoverPoster>查看</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="150" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'success' : 'danger'" disable-transitions>
              {{ scope.row.status == 0 ? '开启' : '关闭' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="320">
          <template slot-scope="scope">
            <el-button type="success" @click="handlerPeriod(scope.row.id)" icon="el-icon-loading" size="mini">课程期数
            </el-button>
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemove(scope.row.id)" icon="el-icon-delete" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]"
                     :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>

    <el-dialog
        title="查看分享海报模板"
        :visible.sync="lookPosterImgShow"
        width="30%"
        :before-close="posterImgHandleClose">
      <div>
        <img :src="currentPosterImg" width="100%" height="100%" style="display: block;"/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookPosterImgShow = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 弹框 -->
    <el-dialog :title="form.id ? '编辑线下分类' : '添加线下课程'" :visible.sync="teacherShow" width="60%" @close="handleClose"
               top="6vh">
      <el-form :model="form" ref="form" label-width="120px" class="demo-ruleForm" :rules="rules">
        <div class="layout_sa">
          <el-form-item label="讲师名称:" prop="lecturerId">
            <el-select v-model="form.lecturerId" placeholder="请选择讲师" @focus="clearValidate('lecturerId')">
              <el-option v-for="item in lecturerArr" :key="item.value" :label="item.lecturerName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程名称:" prop="courseName">
            <el-input @focus="clearValidate('courseName')" v-model="form.courseName" placeholder="请输入课程名称"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="课程描述:" prop="courseExplain">
            <el-input v-model="form.courseExplain" @focus="clearValidate('courseExplain')" placeholder="请输入课程描述"
                      autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <div class="layout_sa" style="margin-top:20px;">
          <el-form-item label="课程封面图:" prop="courseImg">
            <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                       :on-success="successImages">
              <img v-if="form.courseImg" :src="form.courseImg" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="课程价格:" prop="price">
            <el-input type="number" @focus="clearValidate('price')" v-model.number="form.price" placeholder="请输入课程原价格"
                      autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="联系电话:" prop="contactMobile">
            <el-input type="number" @focus="clearValidate('contactMobile')" v-model="form.contactMobile"
                      placeholder="请输入联系电话" autocomplete="off"></el-input>
          </el-form-item>
        </div>
        <div class="layout_sa" style="margin-top:20px;">
          <el-form-item label="总报名人数:" prop="totalApplyCount">
            <el-input type="number" @focus="clearValidate('totalApplyCount')" v-model="form.totalApplyCount"
                      placeholder="请输入总报名人数" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="排序值:" prop="sortNum">
            <el-input type="number" @focus="clearValidate('sortNum')" v-model="form.sortNum"
                      placeholder="请输入排序值" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>

          </el-form-item>
        </div>

        <el-form-item label="分享海报模板:" style="margin-top:24px;" label-width="120px" prop="sharePosterImg">
          <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                     :on-success="uploadPosterImageSuccess">
            <img v-if="form.sharePosterImg" :src="form.sharePosterImg" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="课程详情:" style="margin-top:24px;" label-width="120px" prop="courseInfo">
          <wang-editor :height="300" ref="editor"/>
        </el-form-item>
        <el-form-item label="状态:" style="margin-top:10px;">
          <el-switch v-model="statusOpen" active-text="开启" inactive-text="关闭"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {offlineCoursePage, lecturerList, offlineCourseAddOnSet, del} from '../../apis/course'
import {offline} from "@/utils/rules"
import WangEditor from "@/components/wangeditor/wangeditor.vue"

export default {
  components: {WangEditor},
  data() {
    return {
      lookPosterImgShow: false,
      currentPosterImg: '',
      query: {
        current: 1,
        size: 10,
      },
      form: {
        lecturerId: "", // 线下课程讲师id
        courseName: '',	// 线下课程名称
        courseExplain: '',	// 线下课程说明
        courseImg: '',	// 线下课程简要图
        price: '',	// 课程单价
        contactMobile: '',	// 联系电话
        totalApplyCount: '',	//总报名数量
        sortNum: 0,
        sharePosterImg: '',
        status: 0,	// 逻辑删除(0=正常 1=删除)
        courseInfo: '',	// 课程详情
      },
      tableData: [],
      lecturerArr: [], // 教师列表
      loading: false,
      total: null,
      teacherShow: false, // 添加对话框
      rules: offline.rules,
      statusOpen: true
    }
  },
  async created() {
    this.getData()
    const res = await lecturerList()
    this.lecturerArr = res.data
  },
  methods: {
    posterImgHandleClose() {
      this.lookPosterImgShow = false;
    },
    lookPosterImg(img) {
      this.currentPosterImg = img;
      this.lookPosterImgShow = true;
    },
    async getData() {
      this.loading = true
      const res = await offlineCoursePage(this.query)
      const {records, total, current, size} = res.data
      this.tableData = records
      this.total = total
      this.query.current = current
      this.query.size = size
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    handleSizeChange(size) {
      this.query.size = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.query.current = page
      this.getData()
    },

    // 上传图片成功的钩子函数
    successImages(response, file, fileList) {
      this.form.courseImg = response.data
      this.$refs.form.clearValidate('courseImg');
    },
    // 上传海报成功回调
    uploadPosterImageSuccess(response, file, fileList) {
      this.form.sharePosterImg = response.data
    },

    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },

    handlerEdit(item) {
      this.teacherShow = true
      const {courseInfo, status} = item
      const newObj = {...item}
      this.form = newObj
      this.statusOpen = [true, false] [status]
      this.$nextTick(() => {
        this.$refs.editor.setContent(courseInfo)
      })
    },
    handlerRemove(id) {
      this.$confirm('此操作将永久删除课程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await del({id})
        this.query.current = 1
        this.$message({message: '删除成功', type: "success"});
        this.getData()
      }).catch(err => {
      })
    },
    // 添加期数
    handlerPeriod(id) {
      this.$router.push({path: `/periods?id=${id}`});
    },

    handleClose() {
      this.teacherShow = false
      this.clearCen()
    },

    submit() {
      this.form.courseInfo = this.$refs.editor.getContent()
      this.$refs.form.validate(async val => {
        if (!val) return
        this.form.status = this.statusOpen ? 0 : 1
        await offlineCourseAddOnSet(this.form)
        this.$message({message: this.form.id ? '编辑成功' : '添加成功', type: "success"});
        this.teacherShow = false
        this.getData()
        this.clearCen()
      })
    },

    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.statusOpen = true
      this.$refs.editor.clearContent()
      delete this.form.id // 删除对象里面的id
    },
  }

}
</script>

<style lang="less" scoped>

.poster-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .look-poster {
    padding: 0 10px;
    font-size: .9em;
    color: #409eff;
    text-decoration: underline;
  }

  .look-poster:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.el-form-item {
  flex: 1;
  margin-bottom: 0;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 60px;
  border: 1px dashed #999;
}

.avatar {
  width: 140px;
  height: 60px;
  display: block;
}
</style>